/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* SWIPER */
@import "swiper/css/bundle";

/* ANGULAR MATERIAL */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* Fonts */
@font-face {
  font-family: 'poppins-thin';
  src: url(./assets/font/POPPINS-THIN-250.TTF) format("truetype");
  font-weight: 250;
}
@font-face {
  font-family: 'poppins-light';
  src: url(./assets/font/POPPINS-LIGHT.TTF) format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: 'poppins-semi-bold';
  src: url(./assets/font/POPPINS-SEMIBOLD-600.TTF) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: 'poppins-bold';
  src: url(./assets/font/POPPINS-BOLD-700.TTF) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: 'poppins-black';
  src: url(./assets/font/POPPINS-BLACK.TTF) format("truetype");
  font-weight: bolder;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/font/POPPINS-REGULAR-400.TTF) format("truetype");
  font-weight: normal;
}

:root {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', Helvetica, sans-serif;
    color: #000000;
  }

  html,body {
    height: 100%;
  }

  body {
    background: linear-gradient(#323232, #000);;
  }

  .no-scroll {
    --offset-bottom: auto !important;
    --overflow: auto;
    overflow: auto;
  
    &::part(background) {
      bottom: 0;
    }
  
    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }

  
  ion-app {
    margin: 0 auto;
    overflow: visible;
    max-height: 100%;
    width: 100vw;
  }

  @media only screen and (min-width: 850px) {
    ion-app {
      width: 60vh;
    }
  }
  
  ion-content {
    padding-bottom: calc(1em + env(safe-area-inset-bottom));
    height: 100%;
    // padding-left: 20%;
    // padding-right: 20%;
  }

  /* Alert Forget Password */
  .alert-email .alert-wrapper {
    position: relative;
    .alert-head {
      position: relative;
      .alert-sub-title {
        position: absolute;
        top: 20%;
        left: 90%;
        font-family: 'poppins-light';
        color: #000000;
      }
    }
    h2 {
      color: #a60f1b;
      font-size: 1.2rem;
      font-family: 'poppins-bold';
      margin-top: 0px;
    }
    .alert-message {
      color: #000000;
      font-size: .8rem;
      font-family: 'poppins-bold';
    }
    .alert-button-group {
      position: absolute;
      top: 1%;
      //left: 85%;
      .alert-button {
        border: none;
      }
    }
  }

  .alert-email-forget .alert-wrapper {
    position: relative;
    .alert-head {
      position: relative;
      .alert-sub-title {
        position: absolute;
        top: 20%;
        left: 90%;
        font-family: 'poppins-light';
        color: #000000;
      }
    }
    h2 {
      color: #a60f1b;
      font-size: 1.2rem;
      font-family: 'poppins-bold';
      margin-top: 0px;
    }
    .alert-message {
      color: #000000;
      font-size: .8rem;
      font-family: 'poppins-bold';
    }
    .alert-button-group {
      position: absolute;
      bottom: 10.5%;
      right: 7%;
      z-index: 15;
      width: 52px;
      opacity: 0;
      //left: 85%;
      .alert-button {
        border: none;
      }
    }
  }

  /* Alert Chrono */
  .alert-chrono .alert-wrapper {
    position: relative;
    height: 9.5rem;
    width: 100%;
    .alert-head {
      position: relative;
      .alert-sub-title {
        position: absolute;
        top: -25%;
        left: 90%;
        font-family: 'poppins-semi-bold';
        color: #000000;
      }
    }
    h2 {
      color: #000000;
      font-size: 1rem;
      font-family: 'poppins-light';
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 2rem;
    }
    .alert-message {
      margin-top: .3rem;
      color: #A60F1B;
      font-size: 2.4rem;
      font-family: 'poppins';
    }
    .alert-button-group {
      position: absolute;
      top: 1%;
      left: 85%;
      .alert-button {
        border: none;
      }
    }
  }

    /* Alert Weight */
    .alert-add-kg .alert-wrapper {
      position: relative;
      height: 15rem;
      width: 100%;
      .alert-head.sc-ion-alert-md {
        padding-top: 15px;
        padding-bottom: 10px;
      }
      // center text inside button
      .alert-button-inner.sc-ion-alert-md {
        -ms-flex-pack: center;
        justify-content: center;
      }
      .alert-message.sc-ion-alert-md, .alert-input-group.sc-ion-alert-md {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .alert-head {
        position: relative;
        .alert-sub-title {
          position: absolute;
          top: 25%;
          left: 93%;
          font-family: 'poppins-semi-bold';
          color: #000000;
        }
      }
      h2 {
        color: #000000;
        text-align: left;
        font-size: .9rem;
        font-family: 'poppins-semi-bold';
        letter-spacing: 0px;
        margin: .2rem .4rem 0 0;
      }
      #alert-2-msg {
        display: none;
      }
 
      .alert-input {
        border: #000 solid 1px;
        border-radius: 10px;
      }
      #alert-1-msg {
        margin-bottom: 0px;
      }
      input  {
        height: 35px;
      }
      input[type=number] {
        text-indent: 10px;
        padding-right: 8px;
      }
      input[type=date] {
        text-indent: 6px;
        padding-right: 10px;
      }
      input[type=text] {
        display: none;
      }

      #btn-cancel {
        position: absolute;
        top: 1%;
        left: 85%;
        height: 50px;
        width: 50px;
      }
      .alert-button-group {
        width: 90%;
        margin: 0 auto;
        #btn-confirm {   
          background: #A60F1B;
          border-radius: 12px;
          margin: 0 auto;
          width: 100%;
          padding: .5rem;
          .alert-button-inner {
            color: #fff;
            text-align: center;
            font-size: 1rem;
            text-transform: uppercase;
            font-family: 'poppins';
          }
        }
      }

      // .alert-button-group {
      //   // position: absolute;
      //   // top: 1%;
      //   // left: 85%;
      //   .alert-button {
      //     border: none;
      //   }
      // }
    }

  /* COLOR DOTS */
  .dot-green {
    height: 12px;
    width: 12px;
    background-color: #07CA00;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-orange {
    height: 12px;
    width: 12px;
    background-color: #FF9F0E;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-red {
    height: 12px;
    width: 12px;
    background-color: #BC0000;
    border-radius: 50%;
    display: inline-block;
  }
  /* MODAL */
  .small-modal {
    &::part(backdrop) {
      background-color: #000;
    }
    &::part(content) {
      width: 80%;
      height: 15%;
      margin: auto;
    }
    .count-down {
      span {
        color: #BC0000;
      }
    }
  }
  
  /* DISMISS BACKGROUND BUTTON */
  .button-solid,
  .button-outline {
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }
}

.alert-ctrl-btn{
  margin-top: 35px;
  --background : rgba(174,17,29,1);
  color: white;
  float: right;
}

// RESET STYLE FOR INPUT AUTOFILL
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
{
  border:none !important;
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
}

// POPUP
#countdown {
  font-size: 2.7rem;
  color: #BC0000 !important;
  span {
    color: inherit;
  }
}

// ION-SELECT DISPLAY
@media screen and (min-width: 480px) {
  .popover-wide {
    // position: relative !important;
    left: -100%;
    // left: -50% !important;
  }
}